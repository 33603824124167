<template>
  <section class="main-layout">
    <div class="container-fluid">
      <div class="row justify-content-between align-items-center px-3 mb-5">
        <div class="col-4 col-md-2 col-sm-3 col-xl-2">
          <router-link :to="{name: 'index'}">
            <img src="@/assets/images/logo-bumn.png" alt="BUMN" class="img-fluid w-100"/>
          </router-link>
        </div>
        <div class="col-4 col-md-2 col-sm-3 col-xl-2">
          <router-link :to="{name: 'index'}">
            <img src="@/assets/images/logo-mandiri.png" alt="Bank Mandiri" class="img-fluid w-100"/>
          </router-link>
        </div>
      </div>
      <router-view></router-view>
      <div class="row justify-content-center mt-3">
        <div class="col">
          <img src="@/assets/images/logo-bottom.png" alt="Logo supported" class="img-fluid" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "base-layout",
};
</script>