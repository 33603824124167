<template>
  <section>
    <div class="row justify-content-center mb-5">
      <div class="col-10">
        <input v-model="form.keyword" class="form-control outline-light text-primary"
               placeholder="Silakan ketik nama anda..."/>
      </div>
    </div>
    <div v-if="form.keyword" class="row justify-content-center mb-5">
      <template v-if="filterMembers.length">
        <div class="col-8" :key="member.id" v-for="member in filterMembers">
          <button @click="selectedMember = member"
                  class="btn btn-light btn-block outline-light text-uppercase text-primary mb-5">
            {{ member.name }}
          </button>
        </div>
      </template>
      <div v-else class="col-8">
        <p class="text-center font-weight-bold text-white mb-5">Data tidak ditemukan</p>
      </div>
    </div>

    <b-modal v-if="selectedMember" title="Selamat Datang!" @ok="submit" @cancel="selectedMember = null" v-model="modal.attendance"
             cancel-title="Batal" ok-title="Check In">
      <p>Halo, {{ selectedMember.name }}!</p>
      <p>
        Selamat Datang dalam acara <br/>
        <strong>Roadshow Customer Gathering Kopra By Mandiri</strong>.
      </p>
      <p v-if="event">Kamu akan check in pada <strong>{{ event.name }}</strong>.</p>
    </b-modal>

    <b-modal v-if="event" :title="event.name" header-bg-variant="primary" header-text-variant="light" :static="true"
             v-model="modal.confirmation" @hide="selectedMember = null; $router.replace({name: 'index'})"
             :no-close-on-backdrop="true" :ok-only="true" ok-title="Saya Mengerti">
      <strong>Berhasil check in.</strong>

      <p v-if="selectedMember">Anda berada pada layout nomor <strong>{{ selectedMember.number }}</strong>.</p>
      <template v-if="event.layout">
        <inline-svg :src="'/event/' + event.layout" class="w-100 h-100"/>
      </template>
    </b-modal>
  </section>
</template>

<script>
import {database} from "@/service/firebase";
import {ref, get, set} from "firebase/database";
import InlineSvg from 'vue-inline-svg';

export default {
  name: 'Search',
  components: {
    InlineSvg,
  },
  props: {
    event: {
      default: null,
      required: false
    }
  },
  watch: {
    selectedMember(member) {
      if (member) {
        let eventGuestRef = ref(database, 'event-guest/' + this.event.id + '/' + member.id);
        get(eventGuestRef).then((snapshot) => {
          let element = document.getElementById('number-' + member.number);
          if (element) {
            element.style.opacity = '1';
          }

          if (!snapshot.exists()) {
            this.modal.attendance = true;
          } else {
            this.modal.confirmation = true;

            let attend = snapshot.val();
            console.log('Already check in at ' + this.$moment(attend.datetime, 'YYYY-MM-DD HH:mm:ss').format('D MMM YYYY - HH.mm'));
          }
        })
      } else {
        let elements = document.querySelectorAll('g[id^="number-"][style*="opacity:"]');
        elements.forEach(function (element) {
          element.style.removeProperty('opacity');
        })
      }
    }
  },
  computed: {
    filterMembers() {
      return this.members.length ?
          this.members.filter(member =>
              member.name.toLowerCase().includes(this.form.keyword.toLowerCase()))
              .slice(0, 3) :
          []
    }
  },
  methods: {
    submit() {
      let eventGuestRef = ref(database, 'event-guest/' + this.event.id + '/' + this.selectedMember.id);
      set(eventGuestRef, {datetime: this.$moment().format('YYYY-MM-DD HH:mm:ss')}).then(() => this.modal.confirmation = true);
    }
  },
  data() {
    return {
      form: {
        keyword: '',
        attendance: {}
      },
      members: [],
      modal: {
        attendance: false,
        confirmation: false
      },
      selectedMember: null
    };
  },
  mounted() {
    let guestRef = ref(database, 'guest');
    get(guestRef).then((snapshot) => {
      if (snapshot.exists()) {
        this.members = Object.values(snapshot.val()).filter(e => e)
      }
    });
  }
}
</script>