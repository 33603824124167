<template>
  <section>
    <div class="row justify-content-between">
      <div class="col-8 col-sm-5 col-md-4 col-lg-4 mr-auto">
        <img src="@/assets/images/banner.png" alt="Banner" class="img-fluid w-100 ml-5 mb-5"/>
      </div>
      <div class="col-10 col-sm-6 col-md-7 col-lg-6 mx-auto">
        <h2 class="text-light text-center">
          Event has ended<br />
          <small>See you on next event!</small>
        </h2>

        <b-overlay v-if="false" :show="loading" variant="transparent" blur="1px">
          <form @submit.prevent="submit">
            <input v-model="form.name" type="text" placeholder="Full Name" maxlength="100"
                   class="form-control form-control-lg text-info mb-1" required/>

            <input v-model="form.institution" type="text" placeholder="Institution" maxlength="100"
                   class="form-control form-control-lg text-info mb-1"
                   required/>

            <input v-model="form.title" type="text" placeholder="Job Title" maxlength="100"
                   class="form-control form-control-lg text-info mb-1" required/>

            <div class="position-relative">
              <input v-model="form.email" type="email" placeholder="Email" maxlength="100"
                     class="form-control form-control-lg text-info mb-1"
                     required/>
              <button type="button" class="btn btn-link position-absolute text-info"
                      v-b-tooltip="'We will send invitation via valid email address'"
                      tabindex="-1"
                      style="top: 6px; right: 4px;">
                <font-awesome-icon icon="info-circle" size="lg"/>
              </button>
            </div>

            <input v-model="form.number" type="tel" placeholder="Phone Number" maxlength="15"
                   class="form-control form-control-lg text-info mb-1"
                   required/>

            <div class="row justify-content-center mt-4">
              <div class="col-10">
                <button type="submit" class="btn btn-light btn-lg btn-block text-uppercase text-primary mb-3">
                  Submit
                </button>
              </div>
              <p class="text-light mt-2 small" v-if="resendMember">
                Did not receive any invitation mail?
                <a href="#" @click.once.stop.prevent="resendEmail" class="text-info">
                  Resend Invitation
                  <font-awesome-icon icon="paper-plane"/>
                </a>
              </p>
            </div>

          </form>
        </b-overlay>
      </div>
    </div>
  </section>
</template>

<script>

import {database} from "@/service/firebase";
import {ref, get, set, update} from "firebase/database";

export default {
  name: 'Register',
  data() {
    return {
      form: {
        name: "",
        number: "", //Phone
        email: "",
        title: "",
        institution: "",
      },
      members: [],
      loading: false,
      resendMember: null
    };
  },
  methods: {
    submit() {
      this.loading = true;

      this.validate().then(success => {
        if (success) {
          let params = Object.assign({}, this.form, {
            id: this.$moment().format('x'),
            datetime: this.$moment().format('YYYY-MM-DD HH:mm:ss Z')
          });
          let reference = ref(database, 'guest/' + params.id);
          set(reference, params).then(() => {
            this.sendEmail().then(document => {
              update(reference, {documentId: document.id});

              this.$root.$bvToast.toast(`Invitation will be sent to ${params.email}. Please consider check spam folder and ensure your email address spelling correctly`, {
                title: 'Thanks for your registration',
                autoHideDelay: 5000,
                variant: "primary",
                solid: true
              })
            });

            this.loading = false;
            this.$router.replace({name: 'index'});
          });
        } else {
          this.loading = false;
        }
      });
    },
    validate() {
      let promise = new Promise(resolve => {
        if (!this.members.length) {
          let guestRef = ref(database, 'guest');
          get(guestRef).then((snapshot) => {
            if (snapshot.exists()) {
              this.members = Object.values(snapshot.val()).filter(member => member.datetime);
            }
            resolve(this.members);
          });
        } else {
          resolve(this.members);
        }
      });

      return promise.then(members => {
        Object.keys(this.form).forEach(attribute => this.form[attribute] = this.form[attribute].trim());
        this.form.email = this.form.email.toLowerCase();

        let member = members.find(member => member.email === this.form.email);
        if (member && member.documentId) {
          this.$root.$bvToast.toast(`We already send invitation email, please check regularly. ref#${member.id}`, {
            title: 'Email has been registered',
            autoHideDelay: 5000,
            variant: "warning",
            solid: true
          })

          this.resendMember = member;
        }
        return member === undefined;
      })
    },
    sendEmail() {
      return this.$store.dispatch('sendEmail', this.form);
    },
    resendEmail() {
      this.loading = true;

      return this.$store.dispatch('sendEmail', this.resendMember).then(document => {
        if (this.resendMember) {
          let reference = ref(database, 'guest/' + this.resendMember.id);
          update(reference, {documentId: document.id});

          this.$root.$bvToast.toast(`Invitation will be sent to ${this.resendMember.email}. Please consider check spam folder and ensure your email address spelling correctly`, {
            title: 'Thanks for your registration',
            autoHideDelay: 5000,
            variant: "primary",
            solid: true
          })
        }

        this.loading = false;
        this.$router.replace({name: 'index'});
      });
    }
  },
  mounted() {
    document.title = "Registration";
  }
}
</script>
