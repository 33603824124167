import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseLayout from '../components/layouts/Base'
import MainLayout from '../components/layouts/Main'
import ManagementLayout from '../components/layouts/Management'
import Index from '../views/Index'
import Event from '../views/Event'
import Register from '../views/Register'
import Bumper from '../views/Bumper'
import ManagementLogin from '../views/management/Login'
import ManagementIndex from '../views/management/Index'
import ManagementEvent from '../views/management/Event'
import ManagementBlast from '../views/management/Blast'
import ManagementCertificate from '../views/management/Certificate'
import Error from '../views/Error'

import Store from '@/store/index.js';

Vue.use(VueRouter)

const routes = [
    {
        path: '', component: BaseLayout, children: [
            {
                path: '', component: MainLayout, children: [
                    {path: '/', name: 'index', component: Index},
                    {path: '/event/:id(\\d+)', name: 'event', component: Event, props: true},
                    {path: '/register', name: 'register', component: Register},
                    {path: '/bumper', name: 'bumper', component: Bumper},
                    {path: "/report", name: 'report', beforeEnter: () => window.location.replace("https://s.id/msf-book-report")},

                    /**
                     * Error Page
                     */
                    {path: "/error", name: "error", component: Error, props: true},
                ],
            },

            /**
             * Management
             */
            {path: '/management/login', name: 'management/login', component: ManagementLogin},
            {
                path: '', component: ManagementLayout, children: [
                    {
                        path: '/management',
                        name: 'management/index',
                        component: ManagementIndex,
                    },
                    {
                        path: '/management/event/:id(\\d+)',
                        name: 'management/event',
                        component: ManagementEvent,
                        props: true,
                    },
                    {
                        path: '/management/blast',
                        name: 'management/blast',
                        component: ManagementBlast,
                    },
                    {
                        path: '/management/certificate',
                        name: 'management/certificate',
                        component: ManagementCertificate,
                    },
                ],
                beforeEnter: authenticate
            },
        ]
    },
    /**
     * Catch undefined route to error page
     */
    {path: "*", redirect: {name: 'error', params: {code: 404, title: "Page Not Found"}}}
]

function authenticate(to, from, next) {
    if (!Store.getters.isAuthenticated) {
        next({name: 'management/login', replace: true});
    } else {
        next();
    }
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
