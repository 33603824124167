<template>
  <section>
    <div class="row mb-5">
      <div class="col text-center">
        <router-link :to="{name: 'index'}">
          <img src="@/assets/images/icon-home.png" alt="Home" style="width: 60px"/>
        </router-link>
      </div>
    </div>
    <Search v-if="event" :event="event"/>
  </section>
</template>

<script>
import Search from "@/components/Search.vue";
import {database} from "@/service/firebase";
import {get, ref} from "firebase/database";

export default {
  name: 'Event',
  props: {
    id: {
      required: true
    }
  },
  components: {
    Search
  },
  data() {
    return {
      event: null
    };
  },
  mounted() {
    let eventRef = ref(database, 'event/' + this.id);
    get(eventRef).then((snapshot) => {
      this.event = snapshot.val()
      if (snapshot.exists() && this.event && this.event.active) {
        document.title = this.event.name;
      } else {
        this.$router.replace({
          name: "error", params: {code: 404, title: "Event not found"}
        })
      }
    });
  }
}
</script>
