<template>
  <section>
    <div class="row justify-content-center align-items-center mb-5">
      <div class="col-10">
        <b-overlay v-bind:show="isProcessing"
                   rounded
                   opacity="0.6"
                   spinner-small
                   spinner-variant="primary"
        >
          <form @submit.prevent="blast">
            <div class="form-group">
              <label class="text-light">Subject</label>
              <input v-model="form.message.subject" class="form-control" required/>
            </div>
            <div class="form-group">
              <label class="text-light">HTML Content</label>
              <textarea v-model="form.message.content" class="form-control rounded"
                        style="min-height: 10em; font-family: monospace" required/>
            </div>

            <div class="row justify-content-around">
              <div class="col-auto">
                <router-link :to="{name: 'management/index'}">
                  <img src="@/assets/images/icon-home.png" alt="Home" style="width: 60px"/>
                </router-link>
              </div>
              <div class="col-auto">
                <button type="submit" class="btn btn-light text-primary mt-3">
                  Send Blast Certificate
                  <font-awesome-icon icon="paper-plane"/>
                </button>
              </div>
            </div>
          </form>
        </b-overlay>
      </div>
    </div>
    <div class="row justify-content-center mb-5">
      <div class="col-10">
        <input v-model="form.keyword" class="form-control outline-light text-primary"
               placeholder="Silakan filter nama peserta..."/>
      </div>
    </div>
    <div class="row justify-content-center mb-5">
      <template v-if="filterMembers.length">
        <div class="col-10">
          <table class="table text-white">
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Institution</th>
              <th>Title</th>
            </tr>
            <tr :key="member.id" v-for="member in filterMembers">
              <td>{{ member.id }}</td>
              <td>{{ member.name }}</td>
              <td>{{ member.email }}</td>
              <td>{{ member.institution }}</td>
              <td>{{ member.title }}</td>

              <vue-html2pdf
                  :show-layout="false"
                  :float-layout="true"
                  :enable-download="false"
                  :preview-modal="false"
                  :manual-pagination="true"
                  :filename="`certificate-msf-${member.id}`"
                  :pdf-quality="2"
                  pdf-format="a4"
                  pdf-orientation="landscape"
                  pdf-content-width="1123px"
                  :ref="`html2Pdf-${member.id}`"
                  @beforeDownload="generate($event, member)"
              >
                <section slot="pdf-content">
                  <section class="pdf-item">
                    <div class="row">
                      <div class="col">
                        <img src="@/assets/images/banner-certificate.jpg" alt="Background" class="img-fluid w-100"/>
                        <h2 class="position-absolute text-light text-truncate"
                            style="top:278px; left: 0px; font-family: 'a Autobus Omnibus'; width: 100%; padding: 16px 70px; margin: 0"
                        >
                          {{ member.name }}
                        </h2>
                      </div>
                    </div>
                  </section>
                </section>
              </vue-html2pdf>
            </tr>
          </table>
        </div>
      </template>
      <div v-else class="col-8">
        <p class="text-center font-weight-bold text-white mb-5">Data tidak ditemukan</p>
      </div>
    </div>
  </section>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import {database} from "@/service/firebase";
import {ref as refDatabase, get as getDatabase, ref, get, set} from "firebase/database";
import {storage} from "@/service/firebase";
import {ref as refStorage, uploadBytes, getDownloadURL} from "firebase/storage";

export default {
  name: 'ManagementCertificate',
  components: {
    VueHtml2pdf
  },
  watch: {
    countSent(value) {
      if (value && this.filterMembers.length <= value) {
        this.countSent = null;
        this.form.keyword = '';
      }
    }
  },
  computed: {
    filterMembers() {
      if (this.members.length) {
        return this.members.filter(member => {
          let keyword = this.form.keyword.toLowerCase();
          return member.name.toLowerCase().includes(keyword) || member.email.toLowerCase().includes(keyword);
        })
      } else {
        return [];
      }
    },
    isProcessing() {
      return this.countSent !== null;
    }
  },
  methods: {
    blast() {
      if (confirm('This will send certificate to filtered member show below. Proceed?')) {
        this.countSent = 0;
        let members = [...this.filterMembers];
        let checkQueue = () => {
          if (members.length) {
            setTimeout(() => {
              if (!this.queue) {
                this.queue = members.shift();
                if (this.queue) {
                  this.render(this.queue);
                }
              }
              checkQueue();
            }, 1000)
          }
        };
        checkQueue();

        this.$root.$bvToast.toast("Please sit down and relax", {
          title: 'Your Certificate Blast in Queue',
          autoHideDelay: 5000,
          variant: "primary",
          solid: true
        })
      }
    },
    async render(member) {
      let ref = `html2Pdf-${member.id}`;
      if (this.$refs[ref] !== undefined) {
        let certificateRef = refDatabase(database, `certificate/${member.id}`);
        await getDatabase(certificateRef).then((snapshot) => {
          if (!snapshot.exists()) {
            this.$refs[ref][0].generatePdf()
          } else {
            this.countSent++;
            this.queue = null;
          }
        });
      }
    },
    async generate({html2pdf, options, pdfContent}, member) {
      let pdf = html2pdf().set(options).from(pdfContent).toPdf().get('pdf');
      let res = await fetch(await pdf.output('bloburl'));
      let blobFile = await res.blob();

      return await this.upload(member, blobFile);
    },
    filename(member) {
      return `${member.name.toLowerCase().replace(/[^\w]/g, '-')}-${member.id}.pdf`;
    },
    async upload(member, file) {
      let reference = refStorage(storage, 'msf/certificate/' + this.filename(member));
      return await uploadBytes(reference, file).then(() => {
        getDownloadURL(reference).then(url => {
          this.$store.dispatch('blastEmail', {
            member: member,
            message: this.form.message,
            attachments: [
              {
                filename: `certificate-msf-${member.id}.pdf`,
                path: url,
              },
            ]
          }).then(document => {
            let reference = refDatabase(database, `certificate/${member.id}`);
            set(reference, {
              datetime: this.$moment().format('YYYY-MM-DD HH:mm:ss Z'),
              documentId: document.id
            }).then(() => {
              this.countSent++;
              console.log(document.id, `${this.countSent}/${this.filterMembers.length}`);

              this.queue = null;
            });
          });
        });
      });
    }
  },
  data() {
    return {
      form: {
        keyword: '',
        message: {
          subject: '',
          content: '',
        },
      },
      members: [],
      queue: null,
      countSent: null,
      exceptions: ['list@mail.msf.co.id', 'bulletin@mail.msf.co.id']
    };
  },
  mounted() {
    let guestRef = refDatabase(database, 'guest');
    getDatabase(guestRef).then((snapshot) => {
      if (snapshot.exists()) {
        this.members = Object.values(snapshot.val()).filter(member => !this.exceptions.includes(member.email))
      }
    });
  }
}
</script>
