<template>
  <section>
    <div class="row">
      <div class="col-8 col-sm-7 col-md-6 col-xl-6 mt-5">
        <img src="@/assets/images/banner.png" alt="Banner" class="img-fluid w-100 ml-5"/>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-9 col-sm-9 col-md-9 col-xl-9 pr-0">
        <img src="@/assets/images/banner-side.png" alt="Banner" class="img-fluid w-100"
             style="transform: scaleX(-1); position: relative; top: -3rem"/>
      </div>
    </div>
    <div class="row justify-content-center" style="margin-bottom: 5rem;">
      <div class="col-auto" v-if="button">
        <drag-verify
            id="drag-verify"
            :height="config.height"
            :width="config.width"
            text="Swipe to launch"
            successText="Thank you!"
            background="#fff"
            handlerBg="#1a69a6"
            progressBarBg="#3aafe3"
            completedBg="#1a69a6"
            :textSize="config.textSize"
            @passcallback="callback"
        />
      </div>
      <div class="col-auto" v-else>
        <button @click="button = true" type="button" class="btn btn-link text-light">
          <font-awesome-icon icon="redo"/>
        </button>
      </div>
    </div>

    <b-modal v-model="modal" @hide="button = false" :static="true" size="" centered hide-header
             hide-footer no-close-on-backdrop no-close-on-esc
             body-class="p-0" dialog-class="modal-dialog-hero m-0">
      <video id="video-bumper" class="w-100 d-block" preload="auto" muted>
        <source :src="videoUrl" type="video/mp4">
        Your browser does not support HTML video.
      </video>
    </b-modal>
  </section>
</template>

<script>
import dragVerify from 'vue-drag-verify';

export default {
  name: 'Bumper',
  components: {
    dragVerify
  },
  methods: {
    callback() {
      let element = document.getElementById('video-bumper');
      element.currentTime = 0;
      element.load();
      element.onended = function () {
        setTimeout(() => this.modal = false, 1000)
      }.bind(this)

      setTimeout(() => {
        this.modal = true;

        element.play();
      }, 1000);
    },
    onResize() {
      let width = window.outerWidth;
      if (width < 500) {
        this.config.width = 450
        this.config.height = 80
        this.config.textSize = '1.5rem'
      } else if (width < 700) {
        this.config.width = 450
        this.config.height = 90
        this.config.textSize = '1.8rem'
      } else if (width < 1000) {
        this.config.width = 600
        this.config.height = 100
        this.config.textSize = '2.5rem'
      }
    },
  },
  data() {
    return {
      modal: false,
      button: true,
      config: {
        width: 800,
        height: 120,
        textSize: '4rem'
      },
      videoUrl: 'https://firebasestorage.googleapis.com/v0/b/api-project-550813444903.appspot.com/o/msf%2Fbumper.mp4?alt=media&token=d80d5435-64db-4781-8e5a-60569d886af2'
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);

      this.onResize();
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
}
</script>

<style>
.drag_verify {
  position: relative;
  overflow: hidden;
}

/**
 * The "shine" element
 */

.drag_verify:after {
  animation: shine 5s ease-in-out infinite;
  animation-duration: 5s;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  left: -70%;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: rotate(310deg);

  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.5) 92%,
      rgba(255, 255, 255, 0.0) 100%
  );
}

/* Hover state - trigger effect */


/* Active state */

.drag_verify:active:after {
  opacity: 0;
}

/* animation */
@keyframes shine {
  10% {
    opacity: 1;
    top: 100%;
    left: 50%;
    transition-property: left, top, opacity;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: 100%;
    left: 50%;
    transition-property: left, top, opacity;
  }
}
</style>