<template>
  <section>
    <div class="row justify-md-content-between justify-content-center">
      <div class="col-8 mr-auto col-md-3 col-lg-3">
        <img src="@/assets/images/banner.png" alt="Banner" class="img-fluid w-100 ml-5 mb-5"/>
        <img src="@/assets/images/banner-side.png" alt="Banner" class="img-fluid w-100 d-none d-md-inline row"/>
      </div>
      <div class="col-11 col-sm-10 col-md-8 col-lg-7 mr-md-4 mr-lg-5">
        <img src="@/assets/images/banner-headline.png" alt="Banner" class="img-fluid w-100 mb-5"/>

        <div class="row justify-content-center mb-4">
          <div class="col-10 col-sm-8 col-lg-7 col-xl-6">
            <div class="row align-items-center mb-5">
              <div class="col">
                <img src="@/assets/images/qr-register.png" alt="QR Registration" class="img-fluid"
                     style="width: 150px; border-radius: 10%;"/>
              </div>
              <div class="col">
                <router-link :to="{name: 'register'}" class="text-light h6">
                  www.msf.co.id/register
                </router-link>
                <p class="text-light font-weight-light small mb-0">*Limited Participant</p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-auto text-center">
                <a :key="link.label" v-for="link in links" :href="link.url" target="_blank"
                   class="btn btn-sm btn-light text-uppercase text-primary mb-4">
                  {{ link.label }}
                  <font-awesome-icon icon="external-link-alt" class="ml-2"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {database} from "@/service/firebase";
import {ref, get} from "firebase/database";

export default {
  name: 'Index',
  data() {
    return {
      links: [],
    };
  },
  mounted() {
    let linkRef = ref(database, 'link');
    get(linkRef).then((snapshot) => {
      if (snapshot.exists()) {
        this.links = snapshot.val()
      }
    }).catch(e => console.log(e));

    document.title = "Home";
  }
}
</script>